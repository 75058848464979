import Modal from "../Modal";

import './RequestModal.css'

import Checkmark from "../../Checkmark/Checkmark";

function RequestModal({ isOpen, onClose, props }) {
  const [message] = props;
  console.log(message)
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="modal__form">
        <span className="form__tag"></span>

        <div className="form__icon">
          <Checkmark animate={isOpen} type={ message ? "warning" : "success"} />
        </div>

        <div className="form__header" style={{textAlign: "center", border: 'none'}}>
          <h2 className='form__title'>{message ? 'Внимание' : 'Заявка отправлена'}</h2>
          <span className="form__subtitle">{message ? 'Возможно произошла ошибка' : 'Рассмотрим в ближайшее время'}</span>
        </div>

        { message ? <span className="form__info" style={{textAlign: "center"}}>{message}</span> : ''}

        <div className="form__footer" style={{marginTop: '1em', justifyContent: "center"}}>
          <button className="form__submit" onClick={onClose}>Хорошо</button>
        </div>
      </div>
    </Modal>
  )
}

export default RequestModal;