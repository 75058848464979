import "./Map.css"
function Map() {
  return (
    <section className="map">
      <iframe style={{border: 0}} title="Карта с адресом ЧУ ДПО Альтернатива" src="https://yandex.ru/map-widget/v1/?um=constructor%3Aec05330fdba5beed079fc28d80d55af1e25fa2fb174860ff9024f90bc163148e&amp;source=constructor" width="100%" height="100%"></iframe>
    </section>
  )
}

export default Map
