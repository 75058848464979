import Inputmask from 'inputmask';
import React, { useState, useEffect } from 'react';

import './ContactsForm.css'
import api from "../../utils/api";

function ContactsForm({showModal}) {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    comment: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    comment: '',
  });

  useEffect(() => {
    // Инициализация маски для поля с телефоном
    const phoneInput = document.getElementById('phone-number');
    Inputmask({
      mask: "+7(999)999-99-99",
      placeholder: "_",
      clearMaskOnLostFocus: false
    }).mask(phoneInput);
  }, []);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validateField(name, value);
  };

  const validateField = (inputName, value) => {
    let errorMessage = '';

    if ( inputName === 'name' && value.trim() === '' ) {
      errorMessage = 'Имя не может быть пустым'
    } else if ( inputName === 'name' && value.trim().length < 2 ) {
      errorMessage = 'Имя слишком короткое'
    } else if ( inputName === 'phone' && value.trim() === '' ) {
      errorMessage = 'Телефонный номер не может быть пустым'
    } else if ( inputName === 'phone' && ~value.indexOf('_')) {
      errorMessage = 'Телефонный номер слишком короткий'
    } else if ( inputName === 'phone' && !Inputmask.isValid(value) ) {
      errorMessage = 'Введите корректный телефонный номер'
    } else if ( inputName === 'comment' && value.trim() === '' ) {
      errorMessage = 'Комментарий не может быть пустым' // комментарий пока не оьязательный сделал
    }

    setErrors((prevErrors) => ({ ...prevErrors, [inputName]: errorMessage }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formErrors = Object.keys(formData).reduce((acc, key) => {
      validateField(key, formData[key]);
      return acc;
    }, {});

    if (Object.values(formErrors).some(error => error !== '')) {
      return; // Если есть ошибки, не отправляем форму
    }

    api.addNewRequest(formData,'/request')
      .then( (res) => {
        showModal(res)
      })
      .catch( err => showModal({error: err.message}) )
  };

  return (
    <form className="contacts__form" onSubmit={handleSubmit}>
      <div>
        <input
          placeholder="Ваше имя"
          className={`contacts__input ${errors.name ? 'contacts__input_error' : 'contacts__input_valid'}`}
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          autoComplete="off"
          required
        />
        {errors.name && <span className="form__input-error">{errors.name}</span>}
      </div>
      <div>
        <input
          className={`contacts__input ${errors.phone ? 'contacts__input_error' : 'contacts__input_valid'}`}
          type="tel"
          id="phone-number"
          name="phone"
          value={formData.phone || ""}
          onChange={handleChange}
          placeholder="+7(9__)___-__-__"
          autoComplete="off"
          required
        />
        {errors.phone && <span className="form__input-error">{errors.phone}</span>}
      </div>
      <div>
        <textarea
          placeholder="Комментарий"
          className='contacts__input'
          id="comment"
          name="comment"
          value={formData.comment}
          onChange={handleChange}
          rows={4}
          autoComplete="off"
        />
      </div>
      <div>
        <p className='contacts__personal-info'>Отправляя, вы соглашаетесь на обработку ваших персональных данных</p>
        <button className="form__submit" type="submit">ОТПРАВИТЬ</button>
      </div>
    </form>
  );
}

export default ContactsForm;
