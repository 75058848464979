import Modal from "../Modal";

import './CategoryModal.css'

function CategoryModal({ isOpen, onClose, onSubmit, props }) {
  const { title, subTitle, price, info } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="modal__form">
        <span className="form__tag"></span>

        <div className="form__header">
          <h2 className='form__title'>{title}</h2>
          <span className="form__subtitle">{subTitle}</span>
        </div>

        <span className="form__info">{info}</span>

        <div className="form__footer">
          <span className="form__price">{price}</span>
          <button className="form__submit" onClick={onSubmit}>Записаться</button>
        </div>
      </div>
    </Modal>
  )
}

export default CategoryModal;