import { HeaderBlockWithDescription } from "../HeaderBlock/HeaderBlock";

import iconLocation from "../../images/header/location.svg";

import { useSiteInfo } from '../../hooks/SiteProviderHook';

import './HeaderInfo.css';

function HeaderInfo() {
  const { siteInfo, loading } = useSiteInfo(); // получаем данные из контекста

  // Вычисляем значения, используя данные контекста
  const workTime = !loading && siteInfo ? siteInfo.workTime : '...';
  const address = !loading && siteInfo ? siteInfo.address : [{location: '...'}];

  return (
    <HeaderBlockWithDescription
      className='header__info'
      textDescription={workTime}
      textLink={address[0].location}
      link='#contact'
      element={iconLocation}
    />
  );
}

export default HeaderInfo;