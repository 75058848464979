import tg from "../../images/header/social/tg.svg";
import vk from "../../images/header/social/vk.svg";
import viber from "../../images/header/social/viber.svg";
import whatsapp from "../../images/header/social/whatsapp.svg";

import {useSiteInfo} from "../../hooks/SiteProviderHook";

import './HeaderSocials.css';

function HeaderSocials() {
  const { siteInfo, loading } = useSiteInfo(); // получаем данные из контекста

  const socials = !loading && siteInfo ? siteInfo.socials : [{
    href: '',
    text: '...',
    type: ''
  }];

  const socialIcons = {vk, tg, viber, whatsapp}

  return (
    <div className='header__social'>
      {socials.map( ({href, text, type}, index) => (
        <a target="_blank" rel="noreferrer" key={index} style={{height: "32px"}} href={href}>
          <img className="header__social_icon" src={socialIcons[type]} alt={text}/></a>
      ))}
    </div>
  )
}

export default HeaderSocials