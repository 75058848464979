import './HeaderIcon.css';

function HeaderIcon({url}) {
  const spanStyle = {
    backgroundImage: `url(${url})`,
    backgroundSize: '32px, auto, contain', // Размер изображения
  };

  return(
    <span className="header__icon" style={spanStyle}></span>
  )
}

export default HeaderIcon
