import { useEffect } from "react";

import './Modal.css';
import './Form.css';

const Modal = ({ isOpen, onClose, children }) => {

  useEffect(() => {
    if (!isOpen) return;

    const closeByEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    }

    document.addEventListener('keydown', closeByEscape)

    return () => document.removeEventListener('keydown', closeByEscape)
  }, [isOpen, onClose])

  const handleOverlay = (e) => {
    if (e.target === e.currentTarget) {
      // клик по оверлею
      if (e.target.classList.contains('modal_opened')) {
        onClose();
      }
    }
  }

  return (
    <div className={`modal ${isOpen ? "modal_opened" : ""}`} onMouseDown={handleOverlay}>
      <div className='modal__container'>
        {children}
        <button className='modal__close' type='button' onClick={onClose}/>
      </div>
    </div>
  );
};

export default Modal;