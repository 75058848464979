import React, {useState} from "react";
import {Routes, Route, useLocation} from "react-router-dom";

import AccessibilityTools from "../AccessibilityTools/AccessibilityTools";
import AccessibilityModal from "../Modal/AccessibilityModal/AccessibilityModal";

import MainPage from "../Pages/MainPage/MainPage";
import DocumentsPage from "../Pages/DocumentsPage/DocumentsPage";

import {SiteProvider} from "../../providers/SiteProvider";

import './App.css';

function App() {
  const location = useLocation();
  const isMainPage = location.pathname === '/'

  const [isBurgerOpen, setBurgerOpen] = React.useState(false);

  const [isAccessibilityModalOpen, setAccessibilityModalOpen] = React.useState(false);

  const [darkMode, setDarkMode] = useState(true);

  return (
    <SiteProvider>
      <div className="app">
        <Routes>
          <Route path="/" element={
            <>
              <MainPage
                darkMode={darkMode}
                isMainPage={isMainPage}
                isBurgerOpen={isBurgerOpen}
                setBurgerOpen={setBurgerOpen}
                isAccessibilityModalOpen={isAccessibilityModalOpen}
                setAccessibilityModalOpen={setAccessibilityModalOpen}
              />

              <AccessibilityModal isOpen={isAccessibilityModalOpen} onClose={() => setAccessibilityModalOpen(false)}>
                <AccessibilityTools darkMode={darkMode} setDarkMode={setDarkMode} />
              </AccessibilityModal>
            </>
          } />

          <Route path="/documents" element={
            <>
              <DocumentsPage
                darkMode={darkMode}
                isMainPage={isMainPage}
                isBurgerOpen={isBurgerOpen}
                setBurgerOpen={setBurgerOpen}
                isAccessibilityModalOpen={isAccessibilityModalOpen}
                setAccessibilityModalOpen={setAccessibilityModalOpen}
              />

              <AccessibilityModal isOpen={isAccessibilityModalOpen} onClose={() => setAccessibilityModalOpen(false)}>
                <AccessibilityTools darkMode={darkMode} setDarkMode={setDarkMode} />
              </AccessibilityModal>
            </>
          } />
        </Routes>
      </div>
    </SiteProvider>
  );
}


export default App;
