import { Link } from "react-router-dom";
import logo from "../../images/header/logo.png";
import darkLogo from "../../images/header/logo-dark.png";

import './HeaderLogo.css';

function HeaderLogo({darkMode}) {
  return(
    <Link to="/" className="header__logo">
      <img
        className="logo"
        src={!darkMode ? darkLogo : logo}
        alt="Качественное обучение водителей тракторов, бульдозеров, погрузчиков, экскаваторов и вездеходов категорий A, C, D и E в республике Коми в г. Воркута"
      />
    </Link>
  )
}

export default HeaderLogo;
