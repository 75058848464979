import {useSiteInfo} from "../../hooks/SiteProviderHook";

import './Footer.css'

function Footer() {
  const { siteInfo, loading } = useSiteInfo(); // получаем данные из контекста
  const address = !loading && siteInfo ? siteInfo.address : [{location: '...'}];
  const phones = !loading && siteInfo ? siteInfo.phones : [{text: '...', href: ''}];
  const email = !loading && siteInfo ? siteInfo.email : '...';
  const socials = !loading && siteInfo ? siteInfo.socials : [];
  const whatsapp = socials.length > 1 && socials?.[1] ? socials[1] : {text: '...', href: ''};

  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__block">
          <a className="footer__link" href="/">Наши контакты</a>
          <a className="footer__link" href="/">Категории и цены</a>
          <a className="footer__link" href="/">Сведения об образовательной организации</a>
        </div>
        <div className="footer__block">
          <a className="footer__link" href="/">Договор оферты</a>
          <a className="footer__link" href="/">Политика конфиденциальности</a>
        </div>
        <div className="footer__block">
          <a className="footer__link" href={whatsapp.href}>{whatsapp.text}</a>
          <a className="footer__link" href={phones[0].href}>{phones[0].text}</a>
          <a className="footer__link" href={`mailto:${email}`}>{email}</a>
          <a className="footer__link" href="/">{address[0].location}</a>
        </div>
        <p className="footer__copyright">&copy; {new Date().getFullYear()} ЧУ ДПО «Альтернатива»</p>
      </div>
    </footer>
  )
}

export default Footer
