import React, {useRef} from "react";
import Header from "../../Header/Header";
import Menu from "../../Menu/Menu";
import Main from "../../Main/Main";
import Spinner from "../../Spinner/Spinner";
import Categories from "../../Categories/Categories";
import Contacts from "../../Contacts/Contacts";
import Map from "../../Map/Map";
import Footer from "../../Footer/Footer";
import CategoryModal from "../../Modal/CategoryModal/CategoryModal";
import RequestModal from "../../Modal/RequestModal/RequestModal";

import MenuItems from "./MenuItems/MenuItems";

function MainPage({isBurgerOpen, setBurgerOpen, isAccessibilityModalOpen, setAccessibilityModalOpen, darkMode}) {
  const [isCategoryModalOpen, setCategoryModalOpen] = React.useState(false);
  const [isRequestModalOpen, setRequestModalOpen] = React.useState(false);

  const categoriesRef = useRef();
  const contactsRef = useRef();

  const [dataCategoryModal, setDataCategoryModal] = React.useState({});
  const [dataContactModal, setDataContactModal] = React.useState([null]);

  const scrollToCategories = () => {
    categoriesRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToContacts = () => {
    contactsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleCategoryModalClick = (data) => {
    setDataCategoryModal(data)

    setCategoryModalOpen(true)
  };

  const handleSubmitCategoryModal = () => {
    closeModals();

    scrollToContacts()
  }

  const handleRequestModal = (response) => {
    if ( response?.error ) {
      setDataContactModal([response.error]);
    } else {
      setDataContactModal([null])
    }

    setRequestModalOpen(true)
  }

  function closeModals() {
    setCategoryModalOpen(false)

    setRequestModalOpen(false)
  }

  return (
    <>
      <Header darkMode={darkMode}/>

      <Menu
        element={
          <MenuItems
            isBurgerOpen={isBurgerOpen}
            toggleBurger={() => setBurgerOpen(!isBurgerOpen)}
            scrollToCategories={scrollToCategories}
            scrollToContacts={scrollToContacts}
          />
        }
        isBurgerOpen={isBurgerOpen}
        toggleBurger={() => setBurgerOpen(!isBurgerOpen)}
        toggleAccessibilityModal={() => setAccessibilityModalOpen(!isAccessibilityModalOpen)}
      />

      <Main className='main'>
        <Spinner scrollToContacts={scrollToContacts} />
        <Categories ref={categoriesRef} onCategoryModal={handleCategoryModalClick} />
        <Contacts ref={contactsRef} showRequestModal={handleRequestModal} />
        <Map />
      </Main>

      <Footer />

      <CategoryModal props={dataCategoryModal} isOpen={isCategoryModalOpen} onSubmit={handleSubmitCategoryModal} onClose={() => closeModals()} />
      <RequestModal props={dataContactModal} isOpen={isRequestModalOpen} onClose={() => closeModals()} />
    </>
  )
}

export default MainPage