import React, { useState, useEffect, forwardRef } from 'react';

const Section = forwardRef(({ className, id, title, subTitle, children }, ref) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(`.${className}`);
      const sectionTop = section.getBoundingClientRect().top;
      const isMobile = window.innerWidth <= 442;
      const exception = window.innerWidth <= 1024 || window.innerWidth > 1360;
      setIsSticky(!isMobile && sectionTop < -48 && exception);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll); // Добавляем обработчик для изменения размера окна
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll); // Удаляем обработчик при размонтировании компонента
    };
  }, [className]);

  return (
    <section ref={ref} className={className} id={id}>
      <h2 className={`main__title ${isSticky ? 'main__title_sticky' : ''}`}>{title}</h2>
      <h3 className="main__subtitle">{subTitle}</h3>
      {children}
    </section>
  );
});

Section.displayName = 'Section';

export default Section;

