import MenuMobile from "../MenuMobile/MenuMobile";

import AccessibilityButton from "../AccessibilityButton/AccessibilityButton";

import "./Menu.css";

const Menu = ({element, isBurgerOpen, toggleBurger, toggleAccessibilityModal}) => {
  return (
    <nav className={`menu ${isBurgerOpen ? 'menu__open' : ''}`}>
      <MenuMobile isOpen={isBurgerOpen} toggleBurger={toggleBurger}/>

      {element}

      <AccessibilityButton onClick={toggleAccessibilityModal}/>
    </nav>
  );
};

export default Menu;


