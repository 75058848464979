const MAIL = 'nazimovev@mail.ru'

const LISTPHONES = [{
  text: '8(912) 177-79-13',
  href: 'tel:79121777913'
}, {
  text: '-',
  href: '-'
}]


export { MAIL, LISTPHONES }