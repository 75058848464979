import {HeaderBlock} from "../HeaderBlock/HeaderBlock";

import iconMail from "../../images/header/mail.svg";

import {useSiteInfo} from "../../hooks/SiteProviderHook";

import './HeaderContacts.css';

function HeaderContacts() {
  const { siteInfo, loading } = useSiteInfo(); // получаем данные из контекста

  const email = !loading && siteInfo ? siteInfo.email : '...';

  return (
    <HeaderBlock
      className='header__contacts'
      link={`mailto:${email}`}
      textLink={email}
      text="Наша почта"
      element={iconMail}
    />
  );
}

export default HeaderContacts