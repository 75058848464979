import Modal from "../Modal";

function AccessibilityModal({ isOpen, onClose, children }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="modal__form">
        <span className="form__tag"></span>
        {children}
      </div>
    </Modal>
  )
}

export default AccessibilityModal;