class Api {
    constructor({ baseUrl, headers }) {
        this._baseUrl = baseUrl;
        this._headers = headers;
    }

    getInfo(url){
        return this._request( url, {
            method: 'GET'
        })
    }

    addNewRequest(data, url){
      return this._request( url, {
        method: 'POST',
        body: JSON.stringify(data)
      })
    }

    _request(url, options) {
        return fetch(this._baseUrl + url, {
            headers: this._headers,
            credentials: 'include',
            ...options
        }).then(this._checkResponse)
    }

  _checkResponse(res) {
    if (res.ok) {
      return res.json(); // Возвращаем JSON, если все в порядке
    } else {
      // Создаем промис с результатом из JSON и отклоняем его
      return res.json().then(err => {
        // Создаем объект ошибки с полученными данными
        const error = new Error('Ошибка запроса');
        error.response = res;
        error.message = err.message || 'Неизвестная ошибка';
        error.data = err;
        throw error;
      });
    }
  }
}

const api = new Api({
  baseUrl: 'https://altvorkuta.ru/api',
  headers: {
    'Content-Type': "application/json",
  },
});

export default api;