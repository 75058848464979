import {NavLink} from "react-router-dom";

function MenuItems({isBurgerOpen, toggleBurger, scrollToContacts, scrollToCategories}) {
  return (
    <ul className="menu__list">
      <li className="menu__item">
        <NavLink
          to="/documents"
          className="menu__link"
          onClick={() => isBurgerOpen && toggleBurger()}
        >
          Сведения об образовательной организации
        </NavLink>
      </li>
      <li className="menu__item">
        <NavLink
          to="/#categories"
          onClick={(e) => {
            scrollToCategories(e);
            isBurgerOpen && toggleBurger()
          }}
          className="menu__link"
        >
          Категории и цены
        </NavLink>
      </li>
      <li className="menu__item">
        <NavLink
          to="/#contacts"
          onClick={(e) => {
            scrollToContacts(e);
            isBurgerOpen && toggleBurger()
          }}
          className="menu__link"
        >
          Наши контакты
        </NavLink>
      </li>
    </ul>
  )
}

export default MenuItems