import {HeaderBlockTwoLinks} from "../HeaderBlock/HeaderBlock";

import iconPhone from "../../images/header/phone.svg";

import {useSiteInfo} from "../../hooks/SiteProviderHook";

import './HeaderPhones.css';

function HeaderContacts() {
  const { siteInfo, loading } = useSiteInfo(); // получаем данные из контекста

  const phones = !loading && siteInfo ? siteInfo.phones : [{
    href: '',
    text: '...'
  }];

  return (
    <HeaderBlockTwoLinks
      className='header__phones'
      links={phones}
      element={iconPhone}
    />
  );
}

export default HeaderContacts