import React, { useState } from 'react';

import darkThemeIcon from '../../images/modal/dark-theme.png'
import fontPlusIcon  from '../../images/modal/A+.png'
import fontMinusIcon from '../../images/modal/A-.png'
import imageIcon from '../../images/modal/image.png'
import iconLogo from '../../images/header/logo.png'
import darkLogo from "../../images/header/logo-dark.png";

import './AccessibilityTools.css';

const AccessibilityTools = ({darkMode, setDarkMode}) => {
  const [percentZoom, setPercentZoom] = useState(100)
  const [showImages, setShowImages] = useState(true);
  const [iconFlipped, setIconFlipped] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    const root = document.querySelector('#root');
    const mode = darkMode ? 'dark' : 'def';

    const properties = [
      'text-color',
      'background-color',

      'header-font-color',
      'header-background-color',

      'card-background-color',

      'contact-font-color',

      'menu-background-color',
      'menu-font-color',
      'menu-line-color',
      'menu-button-background-color',

      'table-hover-color',

      'form-input-font-color',
      'form-input-background-color',
      'form-background-color',

      'text-color-green',
      'text-color-blue',

      'icon-background-color',
      'button-background-color',

      'footer-text-color',
      'footer-background-color'
    ];

    properties.forEach(prop => {
      root.style.setProperty(`--${prop}`, `var(--${mode}-${prop})`);
    });

    setIconFlipped(!iconFlipped);
  };

  const toggleImages = () => setShowImages(!showImages);

  const updateFontSize = (size) => {
    setPercentZoom(percentZoom + size*5 )

    if ( percentZoom ) {
      const root = document.documentElement.querySelector('#root')
      const getSize = (param) => parseInt(getComputedStyle(root).getPropertyValue(param))

      const variables = [
        '--button-font-size',
        '--title-font-size',
        '--text-font-size',
        '--header-footer-font-size',
        '--main-title-font-size',
        '--main-sub-title-font-size'
      ];

      variables.forEach(variable => {
        root.style.setProperty(variable, `${getSize(variable) + size}px`);
      });
    } else {
      setPercentZoom(0)
    }
  };

  const increaseFontSize = () => updateFontSize(2);
  const decreaseFontSize = () => updateFontSize(-2);

  const flexCenterStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  };

  return (
    <div className="accessibility__tools">
      <div className='accessibility__row'>
        <div className='accessibility__cell'>
          <button className='accessibility__button' onClick={increaseFontSize}>
            <img width='24px' height='24px' src={fontPlusIcon} alt=""/>
            Увеличить шрифт
          </button>

          <button className='accessibility__button' onClick={decreaseFontSize}>
            <img width='24px' height='24px' src={fontMinusIcon} alt=""/>
            Уменьшить шрифт
          </button>
        </div>

        <div style={flexCenterStyle}>
          <span className='accessibility__percent'>{percentZoom}%</span>
        </div>
      </div>

      <p className='accessibility__example'>Пример текста для проверки размера шрифта и цветовой схемы.</p>

      <div className='accessibility__row'>

        <div className='accessibility__cell'>
          <button className='accessibility__button' onClick={toggleDarkMode}>
            <img
              width='24px'
              height='24px'
              src={darkThemeIcon}
              className={iconFlipped ? 'icon__flip' : ''}
              alt="Иконка тёмной или светлой темы"
            />
            {darkMode ? 'Темная тема' : 'Светлая тема'}
          </button>

          <button className='accessibility__button' onClick={toggleImages}>
            <img width='24px' height='24px' src={imageIcon} alt=""/>
            {showImages ? 'Скрыть изображения' : 'Показать изображения'}
          </button>
        </div>

        <div style={flexCenterStyle}>
          <img
            className={`accessibility__image ${showImages ? '' : 'hidden'}`}
            src={ !darkMode ? darkLogo : iconLogo}
            alt="Пример изображения"
          />
        </div>
      </div>
    </div>
  );
};

export default AccessibilityTools;
