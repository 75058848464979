import React, { forwardRef } from 'react';

import {MAIL, LISTPHONES} from "../../utils/constants";

import Section from "../Section/Section";

import './OsnovnyyeSvedenija.css';

const MainInfo = forwardRef((props, ref) => {
  return (
    <Section
      ref={ref}
      title='Основные сведения'
      subTitle=''
      className='basic-info'
      id='osnovnyye-svedenija'
    >
      <div className="documents__section-content">
        <table className="documents__table">
          <tbody>
          <tr>
            <td className="table-td">Полное наименование организации</td>
            <td className="table-td">Частное учреждение дополнительного образования «Альтернатива»</td>
          </tr>
          <tr>
            <td className="table-td">Сокращенное наименование организации</td>
            <td className="table-td">ЧУ ДПО «Альтернатива»</td>
          </tr>
          <tr>
            <td className="table-td">Дата Основания</td>
            <td className="table-td">6 июля 2020 г.</td>
          </tr>
          <tr>
            <td className="table-td">Учредитель</td>
            <td className="table-td">Назимов Евгений Владимирович</td>
          </tr>
          <tr>
            <td className="table-td">Генеральный Директор</td>
            <td className="table-td">Назимов Евгений Владимирович</td>
          </tr>
          <tr>
            <td className="table-td">ИНН</td>
            <td className="table-td">1103041882</td>
          </tr>
          <tr>
            <td className="table-td">КПП</td>
            <td className="table-td">110301001</td>
          </tr>
          <tr>
            <td className="table-td">Основной государственный регистрационный номер</td>
            <td className="table-td">1071100000542</td>
          </tr>
          </tbody>
        </table>

        <h3 className="documents__title">Место нахождения и контакты</h3>
        <table className="documents__table">
          <tbody>
            <tr>
              <td className="table-td">Адрес</td>
              <td className="table-td">169900, г. Воркута, ул. Ленина, д. 7 а – 10</td>
            </tr>
            <tr>
              <td className="table-td">Адрес осуществления образовательной деятельности</td>
              <td className="table-td">169900, г. Воркута, ул. Ленина д. 48б</td>
            </tr>
            <tr>
              <td className="table-td">Телефон</td>
              <td className="table-td"><span className="color-red">{LISTPHONES[0].text}</span></td>
            </tr>
            <tr>
              <td className="table-td">Электронная почта</td>
              <td className="table-td">{MAIL}</td>
            </tr>
            <tr>
              <td className="table-td">Адрес сайта в сети «Интернет»</td>
              <td className="table-td"><a className="documents__site" href="https://altvorkuta.ru">WWW.ALTVORKUTA.RU</a></td>
            </tr>
          </tbody>
        </table>

        <div className='documents__worktime'>
          <h4>Режим приема обращений по телефону</h4>
          <table>
            <tbody>
              <tr>
                <td>Понедельник–пятница</td>
                <td><b>с 10-00 до 20-00</b></td>
              </tr>
              <tr>
                <td>Суббота, воскресенье</td>
                <td><b>с 9-00 до 21-00</b></td>
              </tr>
            </tbody>
            </table>
        </div>
      </div>
    </Section>
)
});

MainInfo.displayName = 'MainInfo';

export default MainInfo