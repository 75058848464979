import {NavLink} from "react-router-dom";

function MenuItems() {
  return (
    <>
      <li className="documents__menu-item">
        <NavLink
          to="/#categories"
          className="documents__menu-link documents__menu-link_selected"
        >
          основные сведения
        </NavLink>
      </li>

      <li className="documents__menu-item">
        <NavLink
          to="/#categories"
          className="documents__menu-link"
        >
          структура и органы управления образовательной организации
        </NavLink>
      </li>
      <li className="documents__menu-item">
        <NavLink
          to="/#contacts"
          className="documents__menu-link"
        >
          документы
        </NavLink>
      </li>
      <li className="documents__menu-item">
        <NavLink
          to="/#contacts"
          className="documents__menu-link"
        >
          образование
        </NavLink>
      </li>
      <li className="documents__menu-item">
        <NavLink
          to="/#contacts"
          className="documents__menu-link"
        >
          руководство. педагогический состав
        </NavLink>
      </li>
      <li className="documents__menu-item">
        <NavLink
          to="/#contacts"
          className="documents__menu-link"
        >
          материально-техническое обеспечение и оснащенность образовательного процесса
        </NavLink>
      </li>
      <li className="documents__menu-item">
        <NavLink
          to="/#contacts"
          className="documents__menu-link"
        >
          платные образовательные услуги
        </NavLink>
      </li>
      <li className="documents__menu-item">
        <NavLink
          to="/#contacts"
          className="documents__menu-link"
        >
          финансово-хозяйственная деятельность
        </NavLink>
      </li>
      <li className="documents__menu-item">
        <NavLink
          to="/#contacts"
          className="documents__menu-link"
        >
          вакантные места для приема (перевода)
        </NavLink>
      </li>
      <li className="documents__menu-item">
        <NavLink
          to="/#contacts"
          className="documents__menu-link"
        >
          международное сотрудничество
        </NavLink>
      </li>
      <li className="documents__menu-item">
        <NavLink
          to="/#contacts"
          className="documents__menu-link"
        >
          доступная среда
        </NavLink>
      </li>
    </>
  )
}

export default MenuItems