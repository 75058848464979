import './MenuMobile.css'

function MenuMobile({isOpen, toggleBurger}) {
  return (
    <>
      <button className="menu__mobile" onClick={toggleBurger}>
        <span></span><span></span><span></span>
      </button>
      <div onClick={toggleBurger} className={`menu__background ${isOpen ? 'menu__background_opened' : ''}`}></div>
    </>
  );
}

export default MenuMobile