import './Card.css'

function Card({title, price, subTitle, info, showModal}) {
  const handleCardClick = () => showModal({title, subTitle, price, info})

  return(
    <div className="card" onClick={handleCardClick}>
      <div className="card__link">
        <span className="card__tag"></span>

        <div className="card__title">{title}</div>
        <span className="card__subtitle">{subTitle}</span>

        <div className="card__content">
          <span className="card__price">{price}</span>
          <span className="card__footer">Подробнее</span>
        </div>
      </div>
    </div>
  )
}

export default Card