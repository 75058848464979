import './AsideMenu.css'

function AsideMenu({children}) {
  return (
    <ul className="documents__menu">
      {children}
    </ul>
  )
}

export default AsideMenu