import HeaderIcon from '../HeaderIcon/HeaderIcon'

import './HeaderBlock.css';
import './HeaderTitle.css';
import './HeaderLink.css';
import './HeaderText.css';

function HeaderBlock({className, element, text, link, textLink}) {
  const classFullName = `header__block ${className}`

  return (
    <div className={classFullName}>
      <HeaderIcon url={element} />

      <div className="header__title">
        {text}
      </div>
      <a className="header__link" href={link}>
        {textLink}
      </a>
    </div>
  );
}

function HeaderBlockTwoLinks({className, element, links}) {
  const classFullName = `header__block ${className}`

  return (
    <div className={classFullName}>
      <HeaderIcon url={element} />
      { links.map( ({text,href}, index) => {
        const classes = `header__link ${ index === 0 ? 'header__link_green' : '' }`;

        return (
          <a className={classes} key={index} href={href}>
            {text}
          </a>
        )
      }) }
    </div>
  );
}

function HeaderBlockWithDescription({className, element, link, textLink, textDescription}) {
  const classFullName = `header__block ${className}`

  return (
    <div className={classFullName}>
      <HeaderIcon url={element} />

      <div className="header__title">
        <a className="header__link header__link_orange" href={link}>
          {textLink}
        </a>
      </div>

      <div className="header__text">
        {textDescription}
      </div>
    </div>
  );
}

export {HeaderBlock, HeaderBlockTwoLinks, HeaderBlockWithDescription}