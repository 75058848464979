import HeaderLogo from "../HeaderLogo/HeaderLogo";
import HeaderInfo from "../HeaderInfo/HeaderInfo";
import HeaderPhones from "../HeaderPhones/HeaderPhones";
import HeaderSocials from "../HeaderSocials/HeaderSocials";
import HeaderContacts from "../HeaderContacts/HeaderContacts";

import "./Header.css";

function Header({darkMode}) {
  return (
    <header className='header'>
        <>
          <HeaderLogo darkMode={darkMode}/>
          <HeaderInfo />
          <HeaderContacts />
          <HeaderPhones />
          <HeaderSocials />
        </>
    </header>
  );
}
export default Header;