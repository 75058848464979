import {useSiteInfo} from "../../hooks/SiteProviderHook";

import './ContactsInfo.css'

function ContactsInfo(){
  const { siteInfo, loading } = useSiteInfo(); // получаем данные из контекста

  const workTime = !loading && siteInfo ? siteInfo.workTime : '...';
  const address = !loading && siteInfo ? siteInfo.address : [{location: '...'}];
  const phones = !loading && siteInfo ? siteInfo.phones : [{text: '...', href: ''}];
  const email = !loading && siteInfo ? siteInfo.email : '...';
  const socials = !loading && siteInfo ? siteInfo.socials : [];

  return (
    <div className='contacts__info'>
      <div className='contacts__info-box'>
        <span className="contacts__info-label">Мы находимся по адресу</span>
        <span className="contacts__info-title contacts__info-title_orange">{address[0].location}</span>
        <span className="contacts__info-subtitle">{workTime}</span>
      </div>

      <div className='contacts__info-box'>
        <span className="contacts__info-label">Подробнее по телефону</span>
        <a href={phones[0].href} className="contacts__info-link">{phones[0].text}</a>
      </div>

      <div className='contacts__info-box'>
        <span className="contacts__info-label">Электронный адрес</span>
        <a href={`mailto:${email}`} className="contacts__info-socials-link">{email}</a>
      </div>

      <div className='contacts__info-box'>
        <span className="contacts__info-label">Мы в социальных сетях</span>
        <div className="contacts__info-socials">
          {socials.map(({href, text}, index) => (
            <a href={href} key={index} target="_blank" rel="noreferrer" className="contacts__info-socials-link">{text}</a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ContactsInfo