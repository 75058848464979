import React, { forwardRef } from 'react';

import Section from "../Section/Section";

import Card from '../Card/Card';

import { useSiteInfo } from '../../hooks/SiteProviderHook';

import './Categories.css';

const Categories = forwardRef((props, ref) => {
  const { onCategoryModal } = props;
  const { categories, loading } = useSiteInfo(); // получаем данные из контекста

  const listCategories = !loading && categories ? categories : [];

  return (
    <Section
      ref={ref}
      title='Категории и цены'
      subTitle='Производится обучение (подготовка/переподготовка/повышение разряда) по категориям и профессиям'
      className='categories' id='categories'
    >
      <div className="categories_cards">
        { listCategories.map( ({ title, data }, index) => (
          <Card showModal={onCategoryModal} title={title} subTitle={data.subtitle} price={data.price} info={data.info} key={index} />
        ))}
      </div>
    </Section>
  )
});

Categories.displayName = 'Categories';

export default Categories;
