import React, {useRef} from "react";
import Header from "../../Header/Header";
import Menu from "../../Menu/Menu";
import Main from "../../Main/Main";
import Footer from "../../Footer/Footer";

import DocumentsItems from "./MenuItems/DocumentsItems";
import AsideMenu from "../../AsideMenu/AsideMenu";
import MenuItems from "./MenuItems/MenuItems";

import OsnovnyyeSvedenija from "../../OsnovnyyeSvedenija/OsnovnyyeSvedenija";

import './documents.css'

function DocumentsPage({isBurgerOpen, setBurgerOpen, isAccessibilityModalOpen, setAccessibilityModalOpen, darkMode}) {
  const osnovnyyeSvedenijaRef = useRef();

  return (
    <>
      <Header darkMode={darkMode}/>

      <Menu
        element={
          <DocumentsItems/>
        }
        isBurgerOpen={isBurgerOpen}
        toggleBurger={() => setBurgerOpen(!isBurgerOpen)}
        toggleAccessibilityModal={() => setAccessibilityModalOpen(!isAccessibilityModalOpen)}
      />
      <Main className='main documents'>
        <aside className='documents__side'>
          <AsideMenu>
            <MenuItems />
          </AsideMenu>
        </aside>
        <div className='documents__content'>
          <OsnovnyyeSvedenija ref={osnovnyyeSvedenijaRef}/>
        </div>
      </Main>
      <Footer />
    </>
  )
}

export default DocumentsPage