import React from "react";

import api from "../utils/api";

import {SiteContext} from "../contexts/SiteContext";

export const SiteProvider = ({ children }) => {
  const [siteInfo, setSiteInfo] = React.useState({ siteInfo: null, categories: null });
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    api.getInfo('/info')
      .then(dataSite => {
        if (dataSite && Array.isArray(dataSite) && dataSite.length) {
          setSiteInfo(prev => ({ ...prev, siteInfo: dataSite[0] }));
        }
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false));
  }, []);

  React.useEffect(() => {
    api.getInfo('/categories')
      .then(categories => {
        if (categories && Array.isArray(categories) && categories.length) {
          setSiteInfo(prev => ({ ...prev, categories }));
        }
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <SiteContext.Provider value={{ ...siteInfo, loading }}>
      {children}
    </SiteContext.Provider>
  );
};
