import {NavLink} from "react-router-dom";

function DocumentsItems() {
  return (
    <ul>
      <li className="menu__item">
        <NavLink
          to="/"
          className="menu__link"
        >
          ← на главную
        </NavLink>
      </li>
    </ul>
  )
}

export default DocumentsItems