import mainImage from "../../images/spinner/main1.jpg";

import './Spinner.css'
function Spinner({scrollToContacts}) {
  const mainImageStyles = {
    background: `linear-gradient(45deg, rgb(208, 77, 77, .7), rgba(104, 145, 105, 0.8)), url(${mainImage}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPositionY: '90%'
  }

  return (
    <section style={mainImageStyles} className="spinner">
      <div className="spinner__content">
        <h1 className="content__title">ЧУ ДПО АЛЬТЕРНАТИВА</h1>

        <h2 className="content__subtitle">Курсы обучения водителей</h2>

        <div>
          <span className="content__text">Трактористы-машинисты категорий</span>

          <div className="content__highlight">
            <span>«В»</span>
            <span>«С»</span>
            <span>«Д»</span>
            <span>«Е»</span>
          </div>
        </div>

        <div>
          <span className="content__text">Внедорожных ТС категорий</span>

          <div className="content__highlight">
            <span>«А1»</span>
            <span>«А2»</span>
            <span>«A3»</span>
            <span>«А4»</span>
          </div>
        </div>

        <div className="content__comments">
          <div>
            <span className="content__text">Профессиональные программы для подготовки водителей</span>

            <div className="content__highlight">
              <span>ПОГРУЗЧИКОВ</span>
              <span>ВЕЗДЕХОДОВ</span>
              <span>ЭКСКАВАТОРОВ</span>
              <span>БУЛЬДОЗЕРОВ</span>
              <span>АВТОГРЕЙДЕРОВ</span>
            </div>
          </div>

          <span className="content__text">По доступным ценам качественное обучение в Воркуте</span>

          <span className="content__text">Не откладывайте свою мечту на потом</span>

          <button onClick={scrollToContacts} className="content__button">ЗАПИСАТЬСЯ</button>
        </div>
      </div>
    </section>
  )
}

export default Spinner
